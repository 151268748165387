<template>
  <cv-modal
    ref="modal"
    :auto-hide-off="false"
    :primary-button-disabled="isSubmitDisabled"
    @primary-click="submit"
  >
    <template
      slot="title"
    >
      {{ title }}
    </template>
    <template
      slot="content"
    >
      <cv-text-input
        class="asset-editor__input"
        label="ISIN"
        v-model="asset.isin"
      />

      <cv-text-input
        class="asset-editor__input"
        label="Exchange"
        v-model="asset.exchange"
      />

      <cv-text-input
        class="asset-editor__input"
        label="Symbol"
        v-model="asset.symbol"
      />

      <cv-text-input
        class="asset-editor__input"
        label="Name"
        v-model="asset.name"
      />
    </template>
    <template slot="primary-button">
      Save
    </template>
  </cv-modal>
</template>

<script>
import {
  CvTextInput,
  CvModal,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import { addAssets, updateAsset } from '@/lib/api';

export default {
  name: 'AddEditAsset',
  components: {
    CvTextInput,
    CvModal,
  },

  data() {
    return {
      id: null,
      isLoading: false,
      asset: {
        exchange: '',
        name: '',
        symbol: '',
        isin: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      getAsset: 'assets/get',
    }),

    isSubmitDisabled() {
      return !this.asset.exchange
      || this.asset.exchange.trim() === ''
      || this.asset.name.trim() === ''
      || this.asset.symbol.trim() === ''
      || this.isLoading;
    },

    isEditing() {
      return !!this.id;
    },

    title() {
      return this.isEditing ? 'Edit asset' : 'Add asset';
    },

    assetToEdit() {
      if (!this.isEditing) return null;
      return this.getAsset(this.id);
    },

  },

  methods: {
    show(id) {
      this.id = id;
      this.setDefaultValues();
      this.$refs.modal.show();
    },

    async submit() {
      this.isLoading = true;
      try {
        if (this.isEditing) {
          await this.update();
        } else {
          await this.add();
        }

        this.$store.dispatch('ledger/calculate');
        this.$refs.modal.hide();
        this.$emit('submit', this.id);
        this.$toasts.success('Added asset');
      } catch (error) {
        this.$toasts.error('Failed to save asset', { subTitle: error.message });
      } finally {
        this.isLoading = false;
      }
    },

    async add() {
      const {
        exchange,
        name,
        symbol,
        isin,
      } = this.asset;

      const assets = await addAssets([{
        exchange,
        name,
        symbol,
        isin,
      }]);

      this.$store.dispatch('assets/add', assets);
      this.id = assets[0].id;
      return assets[0].id;
    },

    async update() {
      const {
        exchange,
        name,
        symbol,
        isin,
      } = this.asset;

      const asset = await updateAsset(this.id, {
        exchange,
        name,
        symbol,
        isin,
      });

      this.id = asset.id;
      this.$store.dispatch('assets/update', [asset]);
    },

    setDefaultValues() {
      this.asset = {
        ...this.asset,
        exchange: '',
        name: '',
        symbol: '',
        isin: '',
      };
      if (!this.isEditing) return;
      const {
        exchange,
        name,
        symbol,
        isin,
      } = this.assetToEdit;

      this.asset = {
        ...this.asset,
        exchange,
        name,
        symbol,
        isin,
      };
    },
  },
};
</script>

<style>
.asset-editor__input {
  margin: 0 0 2rem 0;
}

.asset-editor__input:last-child {
  margin: 0;
}
</style>
