<template>
  <div class="cv-text-input bx--form-item bx--text-input-wrapper">
    <label
      :for="`uid-${id}`"
      class="bx--label"
    >{{ label }}</label>
    <div class="bx--text-input__field-wrapper">
      <input
        type="time"
        :id="`uid-${id}`"
        class="bx--text-input"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'TimePicker',
  props: {
    value: {
      type: String,
      default: '00:00',
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      id: uuidv4(),
    };
  },
};
</script>
