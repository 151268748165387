<template>
  <div class="add-transactions">
    <div class="content-header">
      <h1>{{ pageHeading }}</h1>
    </div>
    <cv-form
      @submit.prevent="submit()"
      class="add-transactions__form"
    >
      <cv-button-set>
        <cv-select
          label="Asset"
          v-model="transaction.assetId"
          v-focus
          :disabled="isAssetSelectDisabled"
        >
          <cv-select-option
            disabled
            value=""
            selected
            hidden
          >
            Choose an option
          </cv-select-option>
          <cv-select-option
            :value="asset.id"
            v-for="(asset) in assets"
            :key="asset.id"
          >
            {{ asset.name }}
          </cv-select-option>
        </cv-select>
        <cv-icon-button
          kind="secondary"
          size="field"
          label="Add asset"
          tip-position="right"
          :icon="Add20"
          class="asset--add-button"
          @click="onAddAssetClick"
          :disabled="isAssetSelectDisabled"
        />
      </cv-button-set>

      <div class="transaction--datetime-field">
        <cv-date-picker
          kind="single"
          pattern="\d{4}/\d{1,2}/\d{1,2}"
          date-label="Date"
          placeholder="yyyy/mm/dd"
          :cal-options="{ dateFormat: 'Y/m/d' }"
          v-model="transaction.date"
        />

        <time-picker
          label="Time"
          v-model="transaction.time"
        />
      </div>

      <cv-select
        label="Type"
        v-model="transaction.type"
        v-focus
        :disabled="isTypeSelectDisabled"
      >
        <cv-select-option
          disabled
          value=""
          selected
          hidden
        >
          Choose an option
        </cv-select-option>
        <cv-select-option value="BUY">
          Buy
        </cv-select-option>
        <cv-select-option value="SELL">
          Sell
        </cv-select-option>
      </cv-select>

      <cv-number-input
        label="Quantity"
        step="0"
        v-model="transaction.quantity"
      />

      <cv-number-input
        label="Price (per share)"
        step="0"
        v-model="transaction.price"
      />

      <cv-number-input
        label="Exchange rate"
        step="0"
        v-model="transaction.exchangeRate"
      />

      <cv-select
        label="Broker currency"
        v-model="transaction.brokerCurrency"
      >
        <cv-select-option
          disabled
          value=""
          hidden
        >
          Choose an option
        </cv-select-option>
        <cv-select-option
          :value="currency"
          v-for="(currency) in currencyIds"
          :key="currency"
        >
          {{ currency }}
        </cv-select-option>
      </cv-select>

      <cv-button-set>
        <cv-button
          :disabled="!canSubmit"
        >
          Save
        </cv-button>
      </cv-button-set>
    </cv-form>
    <add-asset
      ref="addAsset"
      @submit="onAddAsset"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CvForm,
  CvSelect,
  CvSelectOption,
  CvButton,
  CvIconButton,
  CvButtonSet,
  CvNumberInput,
  CvDatePicker,
} from '@carbon/vue';
import Add20 from '@carbon/icons-vue/es/add/20';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { addTrasactions, updateTransaction } from '@/lib/api';
import { currencyIds } from '@/lib/fx';
import TimePicker from '@/components/TimePicker.vue';
import AddAsset from '../Asset/AddEdit.vue';

export default {
  name: 'AddTransaction',

  components: {
    CvForm,
    CvSelect,
    CvSelectOption,
    CvButton,
    CvIconButton,
    CvButtonSet,
    CvNumberInput,
    CvDatePicker,
    TimePicker,
    AddAsset,
  },

  data() {
    return {
      Add20,
      isLoading: false,
      currencyIds,
      transaction: {
        type: null,
        quantity: null,
        assetId: this.$route.params.assetId,
        price: null,
        exchangeRate: null,
        date: null,
        time: null,
        brokerCurrency: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      assets: 'assets/getAll',
      getTransaction: 'transactions/get',
    }),

    pageHeading() {
      if (this.isEditing) {
        return 'Edit transaction';
      }
      return 'Add transaction';
    },

    isAssetSelectDisabled() {
      return this.isAddingForAsset || this.isEditing;
    },

    isTypeSelectDisabled() {
      return this.isEditing;
    },

    isAddingForAsset() {
      return !!this.$route.params.assetId;
    },

    isEditing() {
      return !!this.transactionToEdit;
    },

    transactionToEdit() {
      const id = this.$route.params.transactionId;
      if (!id) return null;
      return this.getTransaction(id);
    },

    canSubmit() {
      const {
        assetId,
        type,
        quantity,
        price,
        exchangeRate,
        date,
        time,
      } = this.transaction;
      return assetId && type && quantity && price
        && exchangeRate && date && time && !this.isLoading;
    },
  },

  methods: {
    onAddAssetClick() {
      this.$refs.addAsset.show();
    },

    async submit() {
      this.isLoading = true;
      try {
        if (this.isEditing) {
          await this.update();
        } else {
          await this.add();
        }

        this.$store.dispatch('ledger/calculate');
        this.$router.push(`/assets/${this.transaction.assetId}`);
        this.$toasts.success('Added transaction');
      } catch (error) {
        this.$toasts.error('Failed to save transaction', { subTitle: error.message });
      } finally {
        this.isLoading = false;
      }
    },

    async add() {
      const {
        assetId,
        type,
        quantity,
        price,
        exchangeRate,
        date,
        time,
      } = this.transaction;

      const datetime = new Date(`${date.replace(/\//g, '-')}T${time}:00Z`);

      const transactions = await addTrasactions([{
        assetId,
        type,
        quantity,
        price,
        exchangeRate,
        datetime,
        importId: uuidv4(),
      }]);
      this.$store.dispatch('transactions/add', transactions);
    },

    async update() {
      const {
        quantity,
        price,
        exchangeRate,
        date,
        time,
      } = this.transaction;

      const datetime = new Date(`${date.replace(/\//g, '-')}T${time}:00Z`);

      const transaction = await updateTransaction(this.transactionToEdit.id, {
        quantity,
        price,
        exchangeRate,
        datetime,
      });

      this.$store.dispatch('transactions/update', [transaction]);
    },

    setValuesIfInEditMode() {
      if (!this.isEditing) return;
      const {
        assetId,
        type,
        quantity,
        price,
        exchangeRate,
        datetime,
      } = this.transactionToEdit;
      this.transaction = {
        ...this.transaction,
        assetId,
        type,
        quantity,
        price,
        exchangeRate,
        date: format(datetime, 'yyyy/MM/dd'),
        time: format(datetime, 'HH:mm'),
      };
      console.log(format(datetime, 'HH:mm'));
    },

    onAddAsset(id) {
      console.log(id);
      this.transaction.assetId = id;
    },

  },

  mounted() {
    this.setValuesIfInEditMode();
  },
};
</script>

<style lang="scss">
  .content-header {
    padding-bottom: 2rem;
  }
  .add-transactions {
    width: 25rem;
  }
  .add-transactions__form > * {
    margin-bottom: 2rem;
  }

  .asset--add-button {
    flex: 0;
    align-self: flex-end;
  }

  .transaction--datetime-field {
    display: flex;
  }
</style>
